import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

const FacebookPixel = () => {
  useEffect(() => {
    const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
    const advancedMatching = {};
    const options = { autoConfig: true, debug: false };

    if (pixelId) {
      ReactPixel.init(pixelId, advancedMatching, options);
      ReactPixel.pageView();
    }
  }, []);

  return null;
};

export default FacebookPixel;

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useResponsive } from "../../hooks";
import { FaBars, FaTimes } from "react-icons/fa";
import { logo } from "../../assets";
import "./NavBar.css";

const NavBar = () => {
  const [click, setClick] = useState(false);
  const location = useLocation(); // Get the current location

  const handleClick = () => setClick(!click);
  const closeMenu = () => setClick(false);

  const { isMobile, isDesktop } = useResponsive();

  return (
    <nav className='navbar'>
      <div className='nav-logo-container'>
        <Link to='/' className='nav-link logo' onClick={closeMenu}>
          <img src={logo} className='nav-logo' alt='logo icon' />
          <p>AWESOME WHALE ADVENTURES</p>
        </Link>
      </div>
      <div className='hamburger' onClick={handleClick}>
        {click ? <FaTimes /> : <FaBars />}
      </div>

      <ul className={click ? "nav-menu active" : "nav-menu"}>
        {/* Only render "ALL TOURS" on mobile */}

        {!isDesktop ? (
          <Link to='/all-tours' className='nav-item' onClick={closeMenu}>
            <li className='nav-link'>ALL TOURS</li>
          </Link>
        ) : (
          <li className='nav-item dropdown'>
            <Link to='/adventure-styles' className='nav-link'>
              ADVENTURE STYLES
            </Link>
            <ul className='dropdown-content'>
              <Link
                to='/all-tours'
                className='nav-link-dropdown'
                onClick={closeMenu}
              >
                <li>All Tours</li>
              </Link>
              <Link
                to='/swim-tours'
                className='nav-link-dropdown'
                onClick={closeMenu}
              >
                <li>Swim Tours</li>
              </Link>
              <Link
                to='/see-tours'
                className='nav-link-dropdown'
                onClick={closeMenu}
              >
                <li>See Tours</li>
              </Link>
            </ul>
          </li>
        )}

        {isMobile && location.pathname !== "/tonga-whale-swim-adventure" && (
          <Link
            to='/tonga-whale-swim-adventure'
            className='nav-link'
            onClick={closeMenu}
          >
            <li className='nav-item'> JOIN US IN TONGA</li>
          </Link>
        )}

        {location.pathname !== "/download-guide" && (
          <Link to='/download-guide' className='nav-link' onClick={closeMenu}>
            <li className='nav-item'>
              {isMobile ? "DOWNLOAD YOUR FREE GUIDE" : "FREE GUIDE"}{" "}
            </li>
          </Link>
        )}

        {location.pathname !== "/blog" && (
          <Link to='/blog' className='nav-link' onClick={closeMenu}>
            <li className='nav-item'>{isMobile ? "OUR BLOG" : "BLOG"}</li>
          </Link>
        )}

        {location.pathname !== "/contact-us" && (
          <Link to='/contact-us' className='nav-link' onClick={closeMenu}>
            <li className='nav-item'>{isMobile ? "CONTACT US" : "CONTACT"}</li>
          </Link>
        )}
      </ul>
    </nav>
  );
};

export default NavBar;

import React, { useState } from "react";
import { useResponsive } from "../../hooks";
import { ContactForm, SEO } from "../index";

const DownloadGuide = () => {
  const [isSent, setIsSent] = useState(false);
  const { isMobile } = useResponsive();

  return (
    <div className='contact-us-page'>
      <div className='contact-us-container'>
        <SEO
          title='Claim Your Whale Adventure Travel Guide'
          description='Free Whale Adventure Travel Guide with top destinations and expert tips.'
          keywords='whale adventure, travel guide, whale watching, free guide'
        />
        {!isSent ? (
          <div className='contact-us-form'>
            <h1>Your Free Guide</h1>
            <h2>Instantly download your free whale adventure travel guide!</h2>
            <p>
              {isMobile
                ? `Uncover top destinations, expert tips, and everything you need to know for your next adventure!`
                : `Whether you’re dreaming of swimming with majestic whales or observing from the comfort of a boat, this guide is your ultimate resource for the best destinations, expert travel tips, and everything you need to know to make your adventure unforgettable.`}
            </p>

            <ContactForm isSent={isSent} setIsSent={setIsSent} />
          </div>
        ) : (
          <div className='thank-you-message'>
            <h1>Thank you!</h1>
            <h3>
              Inside the guide, you'll find everything you need to start
              planning your unforgettable adventure — whether you're swimming
              with humpbacks in the South Pacific or whale watching amongst
              breathtaking fjords.
              <br />
              <br />
              Keep an eye on your inbox for more tips, exclusive offers, and
              updates from Awesome Whale Adventures. If you have any questions
              or need help planning your trip, feel free to reach out to us!
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default DownloadGuide;

import React from "react";
import "./Gallery.css";

const Gallery = ({
  imageUrls,
  isGalleryOpen,
  currentImageIndex,
  openGallery,
  closeGallery,
  nextImage,
  prevImage,
  layout = "modal",
}) => (
  <>
    {layout === "grid" ? (
      <div className='gallery-grid'>
        {Array.isArray(imageUrls) &&
          imageUrls.map((image, index) => (
            <div key={index} className='gallery-item'>
              <img
                src={image}
                alt={`${index + 1}`}
                className='grid-image'
                onClick={() => openGallery(index)}
              />
            </div>
          ))}
      </div>
    ) : (
      <div className='tour-images'>
        {Array.isArray(imageUrls) &&
          imageUrls.length > 1 &&
          imageUrls
            .slice(1)
            .map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Tour Preview ${index + 1}`}
                className='tour-thumbnail'
                onClick={() => openGallery(index)}
              />
            ))}
      </div>
    )}

    {isGalleryOpen && (
      <div className='gallery-modal' onClick={closeGallery}>
        <div className='gallery-content' onClick={(e) => e.stopPropagation()}>
          <button className='close-button' onClick={closeGallery}>
            &times;
          </button>
          <button className='nav-button' onClick={prevImage}>
            &lt;
          </button>
          {imageUrls && (
            <img
              src={imageUrls[currentImageIndex] || "🐋"}
              alt='Gallery view'
              className='expanded-image'
            />
          )}
          <button className='nav-button' onClick={nextImage}>
            &gt;
          </button>
        </div>
      </div>
    )}
  </>
);

export default Gallery;

import React, { useState } from "react";
import { CustomButton } from "../index"; // Assuming you have this reusable button component

const NewsletterForm = ({ onSuccess, errorMessage }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const subscribeWebhookURL = process.env.REACT_APP_MAKE_SUBSCRIBE_URL;

    fetch(subscribeWebhookURL, {
      method: "POST",
      body: JSON.stringify({ firstName, lastName, email }), // Send firstName, lastName, and email
    })
      .then(() => {
        onSuccess(); // Call onSuccess callback when submission succeeds
        setFirstName(""); // Clear first name field
        setLastName(""); // Clear last name field
        setEmail(""); // Clear email field
      })
      .catch(() => {
        console.error("Subscription failed");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type='text'
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        placeholder='First name'
        required
      />
      <input
        type='text'
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        placeholder='Last name'
        required
      />
      <input
        type='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder='Email address'
        required
      />
      <CustomButton
        type='submit'
        text={isLoading ? "Subscribing..." : "Subscribe"}
        disabled={isLoading}
      />
      {errorMessage && <p className='error'>{errorMessage}</p>}
    </form>
  );
};

export default NewsletterForm;

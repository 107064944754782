import { Helmet } from "react-helmet";

const SEO = ({ title, description, keywords, image }) => {
  const defaultTitle = "Awesome Whale Adventures";
  const defaultDescription =
    "Discover unforgettable whale swimming and watching tours with Awesome Whale Adventures.";
  const defaultKeywords =
    "whale tours, whale swimming, ethical tourism, whale watching";
  const defaultImage =
    "https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/humpback-whales";

  const imageUrl = image || defaultImage; // Use passed image or default image

  return (
    <Helmet>
      <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
      <meta name='description' content={description || defaultDescription} />
      <meta name='keywords' content={keywords || defaultKeywords} />

      {/* Overriding Open Graph (Facebook) */}
      <meta property='og:title' content={title || defaultTitle} />
      <meta
        property='og:description'
        content={description || defaultDescription}
      />
      <meta property='og:image' content={imageUrl} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={window.location.href} />

      {/* Overriding Twitter Cards */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title || defaultTitle} />
      <meta
        name='twitter:description'
        content={description || defaultDescription}
      />
      <meta name='twitter:image' content={imageUrl} />
    </Helmet>
  );
};

export default SEO;

import React from "react";
import { CustomButton } from "../index";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "../../hooks";
import "./HeroSection.css";

const HeroSection = () => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  return (
    <section className='landing-page-hero'>
      <div className='hero-background'></div>
      <div className='hero-overlay'></div>
      <div className='landing-hero-content'>
        <h1>Join us and swim with majestic humpback whales in Tonga.</h1>
        {!isMobile && <h2> 30th July - 6th August 2025</h2>}
        <p>Exclusive, ethical adventure in the heart of the South Pacific.</p>
      </div>
      <div className='hero-landing-page-button'>
        <CustomButton
          marginBottom={"0px"}
          className='hero-btn'
          type='button'
          text='Reserve Your Spot Now'
          onClick={() =>
            navigate("/booking-enquiry", {
              state: {
                tour: {
                  country: "Tonga",
                  location: "Landing Page Initial CTA Enquiry",
                  price: "6995",
                  whaleType: "Humpback",
                  swim: true,
                  id: 1,
                },
              },
            })
          }
        />
      </div>
    </section>
  );
};

export default HeroSection;

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CurrencyContext } from "../../context/CurrencyContext"; // Import the CurrencyContext
import { seeIcon, swimIcon } from "../../assets";
import { CustomButton, Icons, ImageHandler } from "../index";
import { CurrencySelector } from "../index";
import { getCurrencySymbol } from "../../utils/currencyUtils"; // Ensure this is imported
import "../TourDetails/TourDetails.css";

const PreviewCard = ({ tour }) => {
  const { currency, setCurrency, loading, convertPrice, rates } =
    useContext(CurrencyContext);
  const navigate = useNavigate();

  const handleClick = () => {
    // Format URL parts based on tour data
    const formattedCountry =
      tour.country?.toLowerCase().replace(/\s+/g, "-") || "unknown-country";
    const formattedWhaleType =
      tour.whaleType?.toLowerCase().replace(/\s+/g, "-") || "unknown-whale";
    const adventureType = tour.swim ? "swim" : "see";
    const formattedURL = `/tours/${
      tour.id || "unknown-id"
    }/${formattedCountry}/${formattedWhaleType}/${adventureType}-adventure`;

    navigate(formattedURL);
  };

  const imageUrls = ImageHandler({ images: tour.images });

  // Convert the price and get the currency symbol
  const convertedPrice = convertPrice(tour.price || 0); // Assuming tour.price is in AUD
  const symbol = getCurrencySymbol(currency); // Get the symbol for the selected currency
  const formattedPrice = convertedPrice.toLocaleString();

  if (loading) {
    return <div>Loading prices...</div>; // Show loading state until rates are fetched
  }

  return (
    <div className='tour-card'>
      <img
        src={imageUrls[0] || "🐋"}
        alt={tour.title || "Tour"}
        className='tour-image'
      />

      <div className='tour-info'>
        <div className='tour-header'>
          <div className='section-header'>
            <img
              src={tour.swim ? swimIcon : seeIcon}
              className={`symbols ${tour.swim ? "swim" : "see"}`}
              alt='tour style icon'
            />

            <div className='price-display'>
              <p className='nights'>
                {tour.noOfNights || "N/A"} nights {tour.from ? "from " : ""}
              </p>
              <div className='current-price'>
                {symbol}
                {formattedPrice}
                {/* Display CurrencySelector next to the price */}
                <CurrencySelector />
              </div>
              <p className='tour-price-details'>pp | twin share</p>
            </div>
          </div>

          <div className='tour-destination'>
            <h2 className='tour-country'>
              {tour.country || "Unknown Country"}
            </h2>
            <div className='tour-dates'>
              <p>{tour.dates || ""}</p>
            </div>
          </div>
        </div>

        <Icons
          swim={tour?.swim || false}
          hotel={tour?.hotel || false}
          liveAboard={tour?.liveAboard || false}
          internalFlights={tour?.internalFlights || false}
          research={tour?.research || false}
          wifi={tour?.wifi || false}
        />

        <div className='tour-description'>
          <p>{tour.intro || "Description not yet available."}</p>
        </div>

        <div className='tour-button-container'>
          <CustomButton text='More Details' onClick={handleClick} />
        </div>
      </div>
    </div>
  );
};

export default PreviewCard;

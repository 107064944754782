// Function to convert price based on selected currency and round up to a full number
export const convertPrice = (price, rates, currency) => {
  if (isNaN(price) || !rates[currency]) return price; // Return the original price if invalid
  // Convert and round up to the nearest full number (integer)
  const converted = price * rates[currency];
  return Math.ceil(converted); // Use Math.ceil() to round up to the nearest integer
};

// Function to get the symbol of the selected currency
export const getCurrencySymbol = (currency) => {
  const currencySymbols = {
    AUD: "$",
    NZD: "NZ$",
    USD: "$",
    CAD: "CA$",
    EUR: "€",
  };
  return currencySymbols[currency] || "$"; // Default to "$" if no symbol found
};

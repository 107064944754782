const Tabs = ({ tour, selectedTab, setSelectedTab }) => {
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <div className='tabs'>
      {selectedTab !== null && (
        <button
          className='tab-button'
          onClick={() => {
            setSelectedTab(null);
            window.scrollTo(0, 0);
          }}
        >
          Back to Overview
        </button>
      )}

      {tour.experience && selectedTab !== "experience" && (
        <button
          className={`tab-button ${
            selectedTab === "experience" ? "active" : ""
          }`}
          onClick={() => handleTabClick("experience")}
        >
          Experience
        </button>
      )}

      {tour.accommodation && selectedTab !== "accommodation" && (
        <button
          className={`tab-button ${
            selectedTab === "accommodation" ? "active" : ""
          }`}
          onClick={() => handleTabClick("accommodation")}
        >
          Accommodation
        </button>
      )}

      {tour.location && selectedTab !== "location" && (
        <button
          className={`tab-button ${selectedTab === "location" ? "active" : ""}`}
          onClick={() => handleTabClick("location")}
        >
          Location
        </button>
      )}

      {tour.dates && selectedTab !== "dates" && (
        <button
          className={`tab-button ${selectedTab === "dates" ? "active" : ""}`}
          onClick={() => handleTabClick("dates")}
        >
          Dates
        </button>
      )}

      {selectedTab !== "ethics" && (
        <button
          className={`tab-button ${selectedTab === "ethics" ? "active" : ""}`}
          onClick={() => handleTabClick("ethics")}
        >
          Ethics
        </button>
      )}
    </div>
  );
};

export default Tabs;

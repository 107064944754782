import React, { createContext, useState, useEffect } from "react";
import { convertPrice } from "../utils/currencyUtils";

const CurrencyContext = createContext();

const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState("AUD"); // Default currency set to AUD
  const [rates, setRates] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchExchangeRates = async () => {
      setLoading(true);
      try {
        const apiKey = process.env.REACT_APP_EXCHANGE_API_KEY;
        const response = await fetch(
          `https://v6.exchangerate-api.com/v6/${apiKey}/latest/AUD`
        );
        const data = await response.json();
        setRates(data.conversion_rates);
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
      setLoading(false);
    };

    fetchExchangeRates();
  }, []);

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        setCurrency,
        rates,
        loading,
        convertPrice: (price) => convertPrice(price, rates, currency), // Use utility function here
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export { CurrencyProvider, CurrencyContext };

import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const CanonicalTag = ({ base }) => {
  const location = useLocation();
  const canonicalUrl = `${base}${location.pathname}`;

  return (
    <Helmet>
      <link rel='canonical' href={canonicalUrl} />
    </Helmet>
  );
};

export default CanonicalTag;

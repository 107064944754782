import React from "react";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "../../hooks";
import "./AdventureTypes.css";
import { seeIcon, swimIcon } from "../../assets";
import { SEO } from "../index";

const AdventureTypes = () => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const goToSwimTours = () => navigate("/swim-tours");
  const goToSeeTours = () => navigate("/see-tours");

  return (
    <div id='adventure-types' className='adventure-types-container'>
      <SEO
        title='Adventures Your Way | Awesome Whale Adventures'
        description='Explore two exciting whale adventure styles with Awesome Whale Adventures. Choose to swim with whales for an unforgettable underwater experience or enjoy guided whale watching tours to see these majestic creatures from above.'
        keywords='whale adventures, whale swimming, whale watching, swim with whales, whale watching tours, adventure styles, choose your adventure, wildlife experiences, ethical whale tours'
      />

      <div className='adventure-types-heading'>
        <h1>Choose Your Adventure Style</h1>
      </div>
      <div className='adventure-types'>
        <div className='adventure swim left' onClick={goToSwimTours}>
          <div className='adventure-header-container'>
            <h2>Swim</h2>
            <img src={swimIcon} className='tour-icon' alt='swim icon' />
          </div>
          {isMobile ? (
            <p className='adventure-text'>
              Discover the ultimate adventure and come eye to eye with these
              gentle giants for a truly profound experience. Join one of our
              guided tours or let us arrange an experience with one of our
              trusted partners, and relax knowing we only work with the most
              ethical operators to ensure every swim is both magical and
              responsible.
            </p>
          ) : (
            <p className='adventure-text'>
              Ready for the ultimate adventure? Imagine coming face to face with
              some of the ocean’s most magnificent creatures in a way that few
              will ever experience. <br />
              <br />
              Whether you’re joining one of our escorted tours or swimming
              alongside whales with our carefully chosen partners, you’ll enjoy
              the peace of mind that comes with knowing we only work with the
              most ethical operators. <br />
              <br />
              Every swim supports the protection and conservation of these
              majestic giants and their habitats, making your encounter as
              responsible as it is unforgettable. Dive into this extraordinary
              experience, where nature’s beauty and power come together in a
              once-in-a-lifetime moment.
            </p>
          )}
        </div>
        <div className='adventure see' onClick={goToSeeTours}>
          <div className='adventure-header-container'>
            <h2>See</h2>
            <img src={seeIcon} className='tour-icon' alt='see icon' />
          </div>
          {isMobile ? (
            <p>
              Not confident in the water or prefer to stay dry? You won’t miss
              out! There are exceptional whale-watching adventures all over the
              world. As incredible as they are in the water, they’re also
              majestic from above, offering breathtaking moments without getting
              wet.
            </p>
          ) : (
            <p>
              If being in the water isn’t for you, you won’t miss out on the
              magic. Our hand-picked multi-day whale-watching tours offer
              front-row seats to one of nature’s greatest shows. <br />
              <br />
              Carefully selected for their exceptional views and ethical
              practices, these tours showcase the whales in their natural
              environment, whether breaching, tail-slapping, or gliding just
              below the surface, here whales display their grace and power right
              before your eyes. <br />
              <br />
              It’s an awe-inspiring experience for those who want to witness
              these magnificent creatures while staying dry. Plus, you can rest
              easy knowing that your adventure helps protect whales for future
              generations.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdventureTypes;

import React, { useContext } from "react";
import { CurrencyContext } from "../../context/CurrencyContext";
import { getCurrencySymbol } from "../../utils/currencyUtils";
import { seeIcon, swimIcon } from "../../assets";
import { CurrencySelector } from "../index";

const TourHeader = ({ tour }) => {
  const { currency, setCurrency, loading, convertPrice } =
    useContext(CurrencyContext);

  // Handle currency change
  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  // Convert price and get the currency symbol
  const convertedPrice = convertPrice(Number(tour.price)); // Assuming tour.price is in AUD
  const symbol = getCurrencySymbol(currency); // Get the symbol for the selected currency
  const formattedPrice = convertedPrice.toLocaleString();

  if (loading) {
    return <div>Loading prices...</div>; // Show loading state until rates are fetched
  }

  return (
    <div className='tour-header'>
      <div className='section-header'>
        <img
          src={tour.swim ? swimIcon : seeIcon}
          className={`symbols ${tour.swim ? "swim" : "see"}`}
          alt='tour style icon'
        />
        <div className='price-display'>
          <p className='nights'>
            {tour.noOfNights || "N/A"} nights {tour.from ? "from " : ""}
          </p>
          <div className='price-container'>
            <div className='current-price'>
              {symbol}
              {formattedPrice}
              <CurrencySelector />
            </div>
          </div>
          <p className='tour-price-details'>pp | twin share</p>
        </div>
      </div>
      <div className='tour-destination'>
        <h2 className='tour-country'>{tour.country || ""}</h2>
        <p className='tour-location'>{tour.location || ""}</p>
      </div>
    </div>
  );
};

export default TourHeader;

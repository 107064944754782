import { useEffect } from "react";

const GoogleAnalytics = () => {
  useEffect(() => {
    const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

    if (GA_MEASUREMENT_ID) {
      const script = document.createElement("script");
      script.defer = true; // Use defer to allow script to load after the document is parsed
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
      document.head.appendChild(script);

      // Initialize Google Analytics
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", GA_MEASUREMENT_ID);
    }
  }, []);

  return null;
};

export default GoogleAnalytics;

import React, { useState } from "react";
import { landingPageFaqs } from "../../data/TourFAQs";
import "./FaqSection.css";

const FaqSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex((prevIndex) => {
      const newIndex = prevIndex === index ? null : index;

      // Use a timeout to wait for DOM reflow when closing a previous item
      setTimeout(() => {
        if (newIndex !== null) {
          const element = document.getElementById(`faq-item-${newIndex}`);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }
      }, 100);

      return newIndex;
    });
  };

  return (
    <div className='faq-section'>
      <h2>Have Questions?</h2>
      <p>Here are answers to the most commonly asked questions.</p>
      {landingPageFaqs.map((faq, index) => (
        <div key={index} id={`faq-item-${index}`} className='faq-item'>
          <div
            className={`faq-question ${openIndex === index ? "open" : ""}`}
            onClick={() => toggleFaq(index)}
          >
            <strong>{faq.question}</strong>
            <span className='faq-toggle-icon'>
              {openIndex === index ? "−" : "+"}
            </span>
          </div>
          {openIndex === index && (
            <div
              className='faq-answer'
              dangerouslySetInnerHTML={{ __html: faq.answer }}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FaqSection;

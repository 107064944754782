import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import { CustomButton } from "../index";
import "./ContactUsForm.css";

const EnquiryForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [guests, setGuests] = useState(1);
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSent, setIsSent] = useState(false);

  // Set default tour info if state is available
  const [tourInfo, setTourInfo] = useState(state?.tour || {});

  useEffect(() => {
    if (state?.tour) {
      setTourInfo(state.tour); // Ensure tourInfo is the tour object
    }
  }, [state]);

  const submit = async (e) => {
    e.preventDefault();

    const webhookURL = process.env.REACT_APP_MAKE_WEBHOOK_ENQUIRY_URL;

    try {
      const response = await fetch(webhookURL, {
        method: "POST",
        body: JSON.stringify({
          fullName,
          email,
          message,
          guests,
          phone,
          isSubscribed,
          tourID: tourInfo.id,
          tourCountry: tourInfo.country,
          tourLocation: tourInfo.location,
          tourPrice: tourInfo.price,
          whaleType: tourInfo.whaleType,
          swim: tourInfo.swim,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }

      // If the submission is successful, navigate to the thank-you page
      setIsSent(true);
      setErrorMessage("");
      navigate("/thank-you", { state: { tour: tourInfo } });
    } catch (error) {
      setErrorMessage(error.message || "There was an error, please try again");
    }
  };

  return (
    <div className='contact-us-page'>
      <div className='contact-us-container'>
        {!isSent && (
          <div className='contact-us-form'>
            {/* Booking Enquiry Card Content */}
            <div className='booking-enquiry-card'>
              <h1>Booking Enquiry</h1>
              {tourInfo && (
                <p>
                  Submit your details below to enquire about the{" "}
                  {tourInfo.whaleType} whale tour in {tourInfo.country} and
                  we'll be in touch with all the details soon!
                </p>
              )}
            </div>

            {/* Enquiry Form */}
            <form className='form-elements' onSubmit={submit}>
              <input
                type='text'
                placeholder='Name (required)'
                required
                onChange={(e) => setFullName(e.target.value)}
                name='full-name'
              />
              <input
                type='email'
                placeholder='Email (required)'
                required
                onChange={(e) => setEmail(e.target.value)}
                name='email'
              />
              <input
                type='tel'
                placeholder='Phone including country code (required)'
                required
                onChange={(e) => setPhone(e.target.value)}
                name='phone'
              />
              <textarea
                placeholder='Message'
                onChange={(e) => setMessage(e.target.value)}
                name='message'
                className='message-text'
              />

              <select
                value={guests}
                onChange={(e) => setGuests(e.target.value)}
                required
                name='guests'
              >
                <option value='' disabled>
                  How many people?
                </option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
              </select>

              <label className='label-container'>
                <input
                  type='checkbox'
                  onChange={(e) => setIsSubscribed(e.target.checked)}
                />
                <span>Subscribe to our news & updates.</span>
              </label>

              <div className='button-container'>
                <CustomButton
                  className='submit-btn'
                  type='submit'
                  text='Submit'
                />
              </div>
              {errorMessage && <p className='error'>{errorMessage}</p>}
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default EnquiryForm;

import React from "react";
import logo from "../../assets/images/logo-with-text.png";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../index";
import "./Hero.css";

const Hero = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/all-tours");
  };

  return (
    <section className='hero'>
      <div className='hero-overlay'>
        <div className='hero-logo-container'>
          <img
            src={logo}
            className='hero-logo-icon'
            alt='Awesome Whale Adventures logo'
            loading='lazy'
          />
        </div>
        <div className='hero-content'>
          <h1>
            Discover and Book Unforgettable Whale Expeditions and Adventures
          </h1>
          <div className='hero-button-container'>
            <CustomButton
              text='Explore All Tours'
              onClick={handleClick}
              aria-label='Navigate to all tours page'
              title='Explore all available whale tours'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

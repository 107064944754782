import React, { useEffect, useState } from "react";
import { dbRealtime } from "../firebase/firebaseConfig";
import { ref, get } from "firebase/database";
import { useLocation } from "react-router-dom";
import { PreviewCard, TourFilter, SEO, Loading } from "../components";
import { ourTour } from "../data/TourBackUpData";
import "./Pages.css";

const AllTours = () => {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState("");
  const [whaleType, setWhaleType] = useState("");
  const [adventureType, setAdventureType] = useState("");
  const [sortOption, setSortOption] = useState({ value: "", order: "" });

  const location = useLocation();

  // Prepare whale types dynamically
  const whaleTypes = [
    ...new Set(
      tours
        .filter((tour) => tour.whaleType)
        .map((tour) => tour.whaleType.toLowerCase())
    ),
  ].sort();

  useEffect(() => {
    const fetchTours = async () => {
      setLoading(true); // Start loading
      const toursRef = ref(dbRealtime, "tours");
      try {
        const snapshot = await get(toursRef);
        if (snapshot.exists()) {
          const toursData = snapshot.val();

          const tourList = Object.entries(toursData).map(([id, tour]) => ({
            id,
            ...tour,
          }));

          setTours(tourList);
        } else {
          console.warn("No tours found in Firebase. Using static data.");
          setTours(ourTour);
        }
      } catch (error) {
        console.error("Error fetching tours:", error);
        setTours(ourTour);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchTours();
  }, []);

  useEffect(() => {
    if (location.pathname === "/swim-tours") {
      setAdventureType("Swim");
    } else if (location.pathname === "/see-tours") {
      setAdventureType("See");
    } else {
      setAdventureType("");
    }
  }, [location]);

  // Filter tours based on selected filters
  const filteredTours = tours.filter((tour) => {
    const normalisedWhaleType = tour.whaleType.trim().toLowerCase();
    const normalisedCountry = tour.country.trim().toLowerCase();
    const normalisedSwim = tour.swim === true;

    return (
      (country === "" || normalisedCountry === country.toLowerCase()) &&
      (whaleType === "" || normalisedWhaleType === whaleType.toLowerCase()) &&
      (adventureType === "" ||
        (adventureType === "Swim" ? normalisedSwim : !normalisedSwim))
    );
  });

  // Sorting logic
  const sortedTours = [...filteredTours].sort((a, b) => {
    const { value, order } = sortOption;

    if (value === "price" || value === "noOfNights") {
      const diff = (a[value] || 0) - (b[value] || 0);
      return order === "asc" ? diff : -diff;
    } else if (value === "country") {
      const diff = (a[value] || "").localeCompare(b[value] || "");
      return order === "asc" ? diff : -diff;
    } else {
      return 0; // Default, no sorting
    }
  });

  // SEO Meta Data - Dynamic SEO content
  const pageTitle = adventureType
    ? `${adventureType} Whale Tours | Awesome Whale Adventures`
    : "All Whale Tours | Awesome Whale Adventures";

  const pageDescription =
    adventureType === "Swim"
      ? "Dive into our curated list of swim with whales tours, where you can experience a once-in-a-lifetime adventure in the ocean."
      : adventureType === "See"
      ? "Explore our exceptional whale watching tours that offer breathtaking views of majestic whales from the safety of a boat."
      : "Explore our curated list of whale watching and swimming tours, available worldwide. Experience breathtaking encounters with whales in the wild.";

  const pageKeywords =
    adventureType === "Swim"
      ? "whale swim, swimming with whales, whale adventures, ocean swim tours"
      : adventureType === "See"
      ? "whale watching, see whales, whale adventure, whale watching tours"
      : "whale tours, swimming with whales, whale watching, adventure travel";

  // Sorting options for the user to choose from
  const sortOptions = [
    { value: "", label: "Default", order: "" },
    { value: "price", label: "Price (High to Low)", order: "desc" },
    { value: "price", label: "Price (Low to High)", order: "asc" },
    {
      value: "noOfNights",
      label: "Number of Nights (High to Low)",
      order: "desc",
    },
    {
      value: "noOfNights",
      label: "Number of Nights (Low to High)",
      order: "asc",
    },
    { value: "country", label: "Country (A to Z)", order: "asc" },
    { value: "country", label: "Country (Z to A)", order: "desc" },
  ];

  return (
    <div className='pages-container'>
      <div className='all-tours-container'>
        <SEO
          title={pageTitle}
          description={pageDescription}
          keywords={pageKeywords}
        />

        <TourFilter
          whaleType={whaleType}
          setWhaleType={setWhaleType}
          adventureType={adventureType}
          setAdventureType={setAdventureType}
          whaleTypes={whaleTypes}
          sortOptions={sortOptions}
          sortOption={sortOption}
          setSortOption={setSortOption}
        />

        <div className='tour-list'>
          {loading ? (
            <Loading message='Loading tours, please wait...' /> // Show loading spinner
          ) : sortedTours.length > 0 ? (
            sortedTours.map((tour) => <PreviewCard key={tour.id} tour={tour} />)
          ) : (
            <div className='message'>
              <p>
                {`${
                  whaleType
                    ? whaleType.charAt(0).toUpperCase() + whaleType.slice(1)
                    : "Whale"
                } ${
                  adventureType === "Swim"
                    ? "swimming"
                    : adventureType === "See"
                    ? "watching"
                    : ""
                } tours are currently unavailable.`}
              </p>
              <p>
                Please note this type of tour may not be available, or future
                dates may not be scheduled yet. Please{" "}
                <a href='/contact-us'>contact us</a> for more information or
                check other options above.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllTours;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faSwimmer,
  faLeaf,
  faHandsHelping,
} from "@fortawesome/free-solid-svg-icons";
import "./KeyFeaturesSection.css";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../index";

const KeyFeaturesSection = () => {
  const navigate = useNavigate();

  return (
    <section className='key-features'>
      <h2>Why Choose Our Tonga Whale Swim Tour?</h2>
      <ul>
        <li>
          <FontAwesomeIcon icon={faUsers} className='large-icon' />
          <div>
            <strong>Small Group Experience</strong>
            <p>
              With just 6-8 guests, you’ll enjoy more time in the water with the
              humpback whales.
            </p>
          </div>
        </li>
        <li>
          <FontAwesomeIcon icon={faSwimmer} />
          <div>
            <strong>Expert Guides</strong>
            <p>
              Certified whale guides prioritise your safety and share in-depth
              knowledge of these incredible creatures.
            </p>
          </div>
        </li>
        <li>
          <FontAwesomeIcon icon={faHandsHelping} />
          <div>
            <strong>Ethical Tourism</strong>
            <p>
              We adhere to responsible guidelines to ensure safe and respectful
              interactions with humpback whales.
            </p>
          </div>
        </li>
        <li>
          <FontAwesomeIcon icon={faLeaf} />
          <div>
            <strong>Idyllic Setting</strong>
            <p>
              Stay in eco-friendly, off-grid private island accommodations in
              Ha'apai surrounded by natural beauty.
            </p>
          </div>
        </li>
      </ul>
      <div className='button-container'>
        <CustomButton
          className='hero-btn'
          type='button'
          text='Learn More About Our Tour'
          onClick={() => navigate("/tours/1/tonga/humpback/swim-adventure")}
          marginBottom={true}
        />
      </div>
    </section>
  );
};

export default KeyFeaturesSection;

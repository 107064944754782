import React, { useState } from "react";
import { CustomButton } from "../index";
import "./ContactUsForm.css";

const ContactForm = ({ isSent, setIsSent }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/awa-guide.pdf";
    link.download = "awa-guide.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const submit = (e) => {
    e.preventDefault();

    const webhookURL = process.env.REACT_APP_MAKE_WEBHOOK_GUIDE_URL;

    fetch(webhookURL, {
      method: "POST",
      body: JSON.stringify({ name, email, isSubscribed }),
    })
      .then(() => {
        setIsSent(true);
        handleDownload();
      })
      .catch(() => {
        setErrorMessage("There was an error, please try again.");
      });
  };

  return (
    <>
      {!isSent && (
        <form onSubmit={submit}>
          <input
            type='text'
            placeholder='Name'
            required
            onChange={(e) => setName(e.target.value)}
            name='name'
            value={name}
          />
          <input
            type='email'
            placeholder='Email'
            required
            onChange={(e) => setEmail(e.target.value)}
            name='email'
            value={email}
          />

          <label className='label-container'>
            <input
              type='checkbox'
              required
              checked={isSubscribed}
              onChange={(e) => setIsSubscribed(e.target.checked)}
            />
            <span>I agree to subscribe for news & updates.</span>
          </label>
          <div className='button-container'>
            <CustomButton
              className='hero-btn'
              type='submit'
              text='Download Your Free Guide Now'
            />
          </div>
          {errorMessage && <p className='error'>{errorMessage}</p>}
        </form>
      )}
    </>
  );
};

export default ContactForm;

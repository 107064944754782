import React, { useContext } from "react";
import { CurrencyContext } from "../../context/CurrencyContext";

const CurrencySelector = () => {
  const { currency, setCurrency, rates, loading } = useContext(CurrencyContext);

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value); // Update the selected currency
  };

  // Check if rates are available and if loading is done
  const showCurrencySelector = !loading && Object.keys(rates).length > 0;

  return (
    <div>
      {showCurrencySelector ? (
        <>
          <select
            id='currency'
            value={currency}
            onChange={handleCurrencyChange}
            className='currency-dropdown'
          >
            <option value='AUD'>AUD</option>
            <option value='NZD'>NZD</option>
            <option value='USD'>USD</option>
            <option value='CAD'>CAD</option>
            <option value='EUR'>EUR</option>
          </select>
        </>
      ) : (
        // If no rates are available or it's still loading, just display AUD
        <div className='base-currency'>
          <label htmlFor='currency'>AUD</label>
        </div>
      )}
    </div>
  );
};

export default CurrencySelector;

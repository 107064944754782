import React, { useState } from "react";
import { CustomButton, SEO } from "../index";

import "./ContactUsForm.css";

const ContactUs = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();

    if (!fullName || !email || !message) {
      setErrorMessage(
        "Please fill out all required fields including name, email and message."
      );
      return;
    }

    setIsLoading(true);
    setErrorMessage(""); // Reset any previous errors

    const webhookURL = process.env.REACT_APP_MAKE_CONTACT_US_URL;

    try {
      const response = await fetch(webhookURL, {
        method: "POST",
        body: JSON.stringify({
          fullName,
          email,
          phone,
          message,
          isSubscribed,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }

      setIsSent(true);
      setFullName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setIsSubscribed(false);
    } catch (error) {
      setErrorMessage(error.message || "There was an error, please try again");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='contact-us-page'>
      <SEO
        title='Contact Us | Awesome Whale Adventures'
        description="Get in touch with Awesome Whale Adventures' expert team to plan your unforgettable whale watching or swimming experience."
        keywords='contact us, whale adventures, whale watching, whale swimming, travel experts, plan your adventure'
      />

      <div className='contact-us-container'>
        {!isSent ? (
          <div className='contact-us-form'>
            <h1>Contact Us</h1>
            <p>
              Have a question or need assistance? We're here to help! Simply
              fill in the form below, or feel free to reach out to us directly
              via <a href='mailto:hello@awesomewhaleadventures.com'>email</a> or
              call our team on <strong>+61 485 933 782.</strong> We look forward
              to connecting with you!
            </p>

            <form onSubmit={submit} className='form-elements'>
              <input
                type='text'
                placeholder='Name (required)'
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                name='full-name'
              />
              <input
                type='email'
                placeholder='Email (required)'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name='email'
              />
              <input
                type='tel'
                placeholder='Phone including country code'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                name='phone'
              />

              <textarea
                placeholder='Message'
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name='message'
                className='message-text'
              />

              <label className='label-container'>
                <input
                  type='checkbox'
                  checked={isSubscribed}
                  onChange={(e) => setIsSubscribed(e.target.checked)}
                />
                <span>Subscribe to our news & updates.</span>
              </label>

              <div className='button-container'>
                <CustomButton
                  className='submit-btn'
                  type='submit'
                  text={isLoading ? "Submitting..." : "Submit"}
                  disabled={isLoading}
                />
              </div>
              {errorMessage && <p className='error'>{errorMessage}</p>}
            </form>
          </div>
        ) : (
          <div className='thank-you-message'>
            <h1>Thank you for your message!</h1>
            <h3>Our team will reach out to you within the next 24-48 hours.</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUs;

import React from "react";
import { CustomButton } from "../index";
import { useNavigate } from "react-router-dom";
import "./FinalCTASection.css";

const FinalCTASection = () => {
  const navigate = useNavigate();

  return (
    <section className='final-cta'>
      <h2>Don’t Miss This Once-in-a-Lifetime Opportunity</h2>
      <p>
        Spaces are limited, and the whales are waiting. Secure your spot today.
      </p>

      <div className='landing-page-button'>
        <CustomButton
          text='Book Your Whale Adventure Now'
          onClick={() =>
            navigate("/booking-enquiry", {
              state: {
                tour: {
                  country: "Tonga",
                  location: "Landing Page Final CTA Enquiry",
                  price: "6995",
                  whaleType: "Humpback",
                  swim: true,
                  id: 1,
                },
              },
            })
          }
        />
      </div>
    </section>
  );
};

export default FinalCTASection;

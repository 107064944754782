import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyle from "./styles/GlobalStyle";
import {
  NavBar,
  Footer,
  DownloadGuide,
  AdventureTypes,
  BlogPost,
  ScrollToTop,
  ContactUs,
  PrivacyPolicy,
  Terms,
  NotFound,
  CanonicalTag,
  NewsletterPopup,
  GoogleAnalytics,
  FacebookPixel,
} from "./components";
import {
  Home,
  Tours,
  AllTours,
  EnquiryPage,
  ThankYouEnquiryPage,
  Blog,
  LandingPageMain,
} from "../src/pages";
import OldPageRedirect from "./components/Redirect/Redirect";

function App() {
  const footerRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const popupShown = localStorage.getItem("popupShown");

    if (!popupShown) {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, []);

  const closePopup = () => {
    setShowPopup(false);
    localStorage.setItem("popupShown", "true");
  };

  const handleSubscribe = (event) => {
    event.preventDefault();
    alert("Thank you for subscribing!");
    closePopup();
  };

  return (
    <Router>
      <>
        <GlobalStyle />
        <CanonicalTag base='https://awesomewhaleadventures.com' />

        <GoogleAnalytics />
        <FacebookPixel />

        {showPopup && (
          <NewsletterPopup onClose={closePopup} onSubscribe={handleSubscribe} />
        )}

        <NavBar />
        <ScrollToTop />
        <Routes>
          <Route
            path='/book-tonga-whale-swim-adventure'
            element={<OldPageRedirect target='/booking-enquiry' />}
          />
          <Route
            path='/'
            element={
              <div className='scroll-container'>
                <Home />
              </div>
            }
          />
          <Route
            path='/tonga-whale-swim-adventure'
            element={<LandingPageMain />}
          />
          <Route path='/adventure-styles' element={<AdventureTypes />} />
          <Route path='/download-guide' element={<DownloadGuide />} />
          <Route path='/all-tours' element={<AllTours />} />
          <Route path='/swim-tours' element={<AllTours />} />
          <Route path='/see-tours' element={<AllTours />} />
          <Route
            path='/tours/:id/:country/:whaleType/:adventureType/:tab?'
            element={<Tours />}
          />
          <Route path='/booking-enquiry' element={<EnquiryPage />} />
          <Route path='/thank-you' element={<ThankYouEnquiryPage />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/:slug' element={<BlogPost />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<Terms />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer ref={footerRef} />
      </>
    </Router>
  );
}

export default App;

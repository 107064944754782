import React from "react";
import ReactPixel from "react-facebook-pixel"; // Import Facebook Pixel
import "./Button.css";

const CustomButton = ({
  text,
  onClick,
  marginTop,
  marginBottom,
  alternateColor,
}) => {
  const handleClick = () => {
    // Track the custom event using trackCustom
    ReactPixel.trackCustom("ButtonClick", {
      buttonName: text,
    });

    // Google Analytics tracking
    if (window.gtag) {
      window.gtag("event", "button_click", {
        event_category: "button",
        event_label: text,
      });
    }

    // Call the passed click handler if exists
    if (onClick) {
      onClick();
    }
  };

  // Default styles with optional inversion
  const buttonStyles = {
    marginTop: marginTop || "14px",
    marginBottom: marginBottom || "14px",
    backgroundColor: alternateColor
      ? "var(--alternate-accent)"
      : "var(--accent-color)",
    color: "var(--site-white)",
  };

  return (
    <button
      className='custom-button'
      onClick={handleClick}
      style={buttonStyles}
    >
      {text}
    </button>
  );
};

export default CustomButton;

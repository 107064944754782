import React from "react";
import "./Copyright.css";

const Copyright = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <p className='copyright-text'>© {currentYear} Awesome Whale Adventures</p>
    </div>
  );
};

export default Copyright;

import React, { useState } from "react";
import "./TourDetails.css";
import {
  CustomButton,
  Icons,
  ImageHandler,
  SEO,
  Tabs,
  TourHeader,
  Gallery,
} from "../index";
import { useNavigate } from "react-router-dom";
import { useGallery, useResponsive } from "../../hooks";

const TourDetails = ({ tour }) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(null);
  const imageUrls = ImageHandler({ images: tour.images });
  const {
    isGalleryOpen,
    currentImageIndex,
    openGallery,
    closeGallery,
    nextImage,
    prevImage,
  } = useGallery(imageUrls);

  const { isMobile } = useResponsive();

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <div className='tour-card'>
      <SEO
        title={`${tour.title} | Awesome Whale Adventures`}
        description={tour.description?.substring(0, 160)}
        keywords={`${tour.title}, whale tour, whale swimming, adventure travel`}
      />
      <img
        src={imageUrls[0] || "🐋"}
        alt={tour.title || "Tour Image"}
        className='tour-image'
      />
      <div className='tour-info'>
        <TourHeader tour={tour} />
        {selectedTab === null && (
          <>
            {/* Show Icons and Overview Information only when no tab is selected */}
            <Icons
              swim={tour.swim || false}
              hotel={tour.hotel || false}
              liveAboard={tour.liveAboard || false}
              internalFlights={tour.internalFlights || false}
              research={tour.research || false}
              wifi={tour.wifi || false}
            />

            <div className='tour-description'>
              <p>{tour.description || "Description not yet available."}</p>
              <p>
                <strong>Your Inclusions:</strong>
              </p>
              <ul>
                {tour.inclusions ? (
                  tour.inclusions
                    .split(";")
                    .map((item, index) => <li key={index}>{item.trim()}</li>)
                ) : (
                  <li>No inclusions listed</li>
                )}
              </ul>

              {/* Show the Gallery (images) only in the overview tab */}
              <Gallery
                imageUrls={imageUrls}
                isGalleryOpen={isGalleryOpen}
                currentImageIndex={currentImageIndex}
                openGallery={openGallery}
                closeGallery={closeGallery}
                nextImage={nextImage}
                prevImage={prevImage}
              />
            </div>
          </>
        )}
        {!isMobile && (
          <Tabs
            tour={tour}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectedTab === "experience" && (
          <div
            className='experience'
            dangerouslySetInnerHTML={{ __html: tour.experience }}
          />
        )}
        {selectedTab === "accommodation" && (
          <div
            className='accommodation'
            dangerouslySetInnerHTML={{ __html: tour.accommodation }}
          />
        )}
        {selectedTab === "ethics" && (
          <section id='ethics' class='tour-ethics'>
            <h2>Respectful Whale Interactions</h2>
            <p>
              At Awesome Whale Adventures, we believe in responsible tourism. We
              ensure that every interaction with the whales is conducted with
              their well-being in mind. This means:
            </p>
            <ul>
              <li>
                <strong>No Pursuit:</strong> We never chase whales. We respect
                their natural movements and only interact with them if they
                willingly approach.
              </li>
              <li>
                <strong>Keeping a Safe Distance:</strong> We maintain a
                respectful distance to allow the whales to feel safe and
                comfortable, ensuring a positive experience for both the whales
                and our guests.
              </li>
              <li>
                <strong>Environmental Consciousness:</strong> Every tour is
                designed with sustainability in mind, preserving natural
                environments for future generations to enjoy.
              </li>
            </ul>

            <h3>Our Commitment to Responsible Partnerships</h3>
            <p>
              At Awesome Whale Adventures, we are committed to working with
              partners who share our values of responsible tourism. Our partners
              are carefully chosen based on their commitment to ethical
              practices. Together, we strive to:
            </p>
            <ul>
              <li>
                <strong>Promote Sustainability:</strong> We partner with
                eco-conscious organisations who help us ensure the protection of
                natural environments, no matter the location.
              </li>
              <li>
                <strong>Maintain Ethical Standards:</strong> All our partners
                adhere to the same respectful approach when interacting with
                whales, prioritising the safety and well-being of these
                magnificent creatures.
              </li>
              <li>
                <strong>Support Local Communities:</strong> We work with local
                businesses and guides who are committed to the sustainable
                development of the regions we operate in, ensuring that tourism
                benefits the local community while protecting the natural beauty
                of the area.
              </li>
            </ul>

            <p>
              When you book a tour with us, you’re supporting a network of
              ethical businesses dedicated to responsible whale tourism.
              Together, we can make a positive impact on the environment and
              local communities across all our destinations.
            </p>
          </section>
        )}

        {selectedTab === "dates" && (
          <div>
            <h2>Upcoming Dates</h2>
            <p>
              {tour.whaleType} whale tours run on set dates and often book out
              well in advance.
            </p>
            <p>
              If the dates you see below don't suit reach out to our team to
              discuss alternatives or advance booking for the following season.
            </p>
            <strong>
              <div
                className='dates'
                dangerouslySetInnerHTML={{ __html: tour.dates }}
              />
            </strong>
            <br />
          </div>
        )}
        {selectedTab === "location" && (
          <div className='tour-location'>
            <h2>Location</h2>
            <p>{`This whale ${
              tour.swim ? `swim` : `watching`
            } adventure takes place in ${tour.location}, ${tour.country}.`}</p>
            <div
              className='experience'
              dangerouslySetInnerHTML={{ __html: tour.locationDetails }}
            />

            <iframe
              title='Tour Location Map'
              width='100%'
              height='300'
              frameBorder='0'
              style={{ border: 0 }}
              src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${encodeURIComponent(
                tour.mapLocation + ", " + tour.country
              )}&zoom=10`}
              allowFullScreen
            ></iframe>
          </div>
        )}

        {isMobile && (
          <>
            <div
              className='experience'
              dangerouslySetInnerHTML={{ __html: tour.experience }}
            />

            <div
              className='accommodation'
              dangerouslySetInnerHTML={{ __html: tour.accommodation }}
            />
            <div className='tour-location'>
              <h2>Location</h2>
              <p>{`This whale ${
                tour.swim ? `swim` : `watching`
              } adventure takes place in ${tour.location}, ${
                tour.country
              }.`}</p>

              <iframe
                title='Tour Location Map'
                width='100%'
                height='300'
                frameBorder='0'
                style={{ border: 0 }}
                src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${encodeURIComponent(
                  tour.mapLocation + ", " + tour.country
                )}&zoom=10`}
                allowFullScreen
              ></iframe>
            </div>
            <div>
              <h2>Upcoming Dates</h2>
              <p>
                {tour.whaleType} whale tours run on set dates and often book out
                well in advance.
              </p>
              <p>
                If the dates you see below don't suit reach out to our team to
                discuss alternatives or advance booking for the following
                season.
              </p>
              <strong>
                <div
                  className='dates'
                  dangerouslySetInnerHTML={{ __html: tour.dates }}
                />
              </strong>
              <br />
            </div>
          </>
        )}
        <div className='tour-button-container'>
          <CustomButton
            text='Enquire Now'
            onClick={() => navigate("/booking-enquiry", { state: { tour } })}
          />
        </div>
      </div>
    </div>
  );
};

export default TourDetails;
